import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../../utils/misc.ts'

const appLayoutVariants = cva('min-h-screen h-auto px-3', {
  variants: {
    appHeader: {
      default: 'pt-0',
      present: 'pt-14',
    },
    appFooter: {
      default: 'pt-0',
      present: 'pb-14',
    },
  },
  defaultVariants: {
    appHeader: 'default',
    appFooter: 'default',
  },
})

export interface AppLayoutProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof appLayoutVariants> {}

const AppLayout = React.forwardRef<HTMLDivElement, AppLayoutProps>(
  ({ className, appHeader, appFooter, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(appLayoutVariants({ appHeader, appFooter, className }))}>
        {props.children}
      </div>
    )
  },
)

export { AppLayout, appLayoutVariants }
